import axios from "axios";
import store from "@/store";
import { clearToken, clearUserInfo } from "./setToken";
import router from "@/router";
import { Message } from "element-ui";

const requestLocal = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 5 * 60 * 1000, // request timeout
});
requestLocal.interceptors.request.use((config) => {
  if (store.state.user.token) {
    config.headers["X-TOKEN"] = store.state.user.token;
  }
  if (config.method === "get" && config.params) {
    let url = config.url + "?";
    for (const propName of Object.keys(config.params)) {
      const value = config.params[propName];
      var part = encodeURIComponent(propName) + "=";
      if (value !== null && typeof value !== "undefined") {
        if (typeof value === "object") {
          for (const key of Object.keys(value)) {
            let params = propName + "[" + key + "]";
            var subPart = encodeURIComponent(params) + "=";
            url += subPart + encodeURIComponent(value[key]) + "&";
          }
        } else {
          url += part + encodeURIComponent(value) + "&";
        }
      }
    }
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }

  return config;
});

requestLocal.interceptors.response.use(
  (response) => {
    let res = response.data;
    if (res.code == 0) {
      if (res.data) {
        return res.data;
      } else {
        return res;
      }
    }
    if (res.code == 101) {
      clearToken();
      clearUserInfo();
      // router.push("/admin/");
      router.push("/");
      Message.error(res.message);
    } else {
      return Promise.reject(res);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default requestLocal;
