<template>
  <div class="content">
    <div class="scene">
      <Title :title="title" :intro="intro" :icon="icon"></Title>
      <div class="scene_box">
        <div class="left">
          <div
            v-for="title in newData"
            :key="title.id"
            @click="change(title.id)"
            :class="title.isShow ? 'active p' : 'p'"
          >
            <div class="circle">
              <div
                class="big"
                :style="{ background: title.isShow ? '#2a90ee' : '' }"
              >
                <div
                  class="min"
                  :style="{ background: title.isShow ? '#2a90ee' : '' }"
                ></div>
              </div>
            </div>
            {{ title.title }}
          </div>
        </div>
        <div
          class="right"
          v-for="title in newData"
          :key="title.id"
          v-show="title.isShow"
        >
          <h2>{{ title.title }}</h2>
          <img :src="title.coverPic" alt="" />
          <h3>{{ title.content.intro }}</h3>
          <p>{{ title.content.content }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "solutionNetDes",
  props: {
    data: Array,
    title: String,
    intro: String,
    icon: String,
  },
  data() {
    return {
      newData: [],
    };
  },
  methods: {
    getNewData() {
      this.newData = this.data.map((item, index) => {
        if (index == 0) {
          item.isShow = true;
        } else {
          item.isShow = false;
        }
        return item;
      });
    },
    change(id) {
      this.newData = this.newData.map((item) => {
        item.isShow = false;
        if (item.id == id) {
          item.isShow = true;
        }
        return item;
      });
    },
  },
  mounted() {
    this.getNewData();
  },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 800px) {
  .active {
    border-right: 6px solid rgb(19, 132, 252);
    background: #ffffff;
    color: #2a90ee;
  }

  h1 {
    font-family: "Arial Normal", "Arial";
    font-weight: 400;
    font-style: normal;
    font-size: 36px;
    letter-spacing: normal;
    color: #333333;
    margin-bottom: 25px;
  }
  .scene {
    width: 100%;
    margin-top: 60px;
    padding: 72px 0;
  }
  .scene h1 {
    text-align: center;
  }
  .scene .scene_box {
    display: flex;
  }
  .scene .scene_box .left {
    border-right: 1px solid #f0f0f5;
  }
  .scene .scene_box .left .circle {
    margin: 0px 14px 0 28px;
  }
  .scene .scene_box .left .circle .big {
    position: relative;
    background: #c9cbd2;
    opacity: 0.8;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  .scene .scene_box .left .circle .min {
    position: absolute;
    background: #c9cbd2;
    opacity: 0.5;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    top: -5px;
    left: 5px;
  }
  .scene .scene_box .left .p {
    padding: 0 6px 0 0;
    display: flex;
    align-items: center;
    height: 100px;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    width: 270px;
    /* B-Gray/B-Gray-900 */
    background: #fbfcff;
    /* B-Gray/B-Gray-800 */
    border-bottom: 1px solid #f0f0f5;
  }
  .scene .scene_box .right {
    margin-left: 24px;
    text-align: left;
  }
  .scene .scene_box .right img {
    object-fit: contain;
    width: 900px;
    object-fit: contain;
    height: 400px;
  }
  .scene .scene_box .right h2 {
    font-size: 22px;
    color: #333333;
    padding-bottom: 10px;
    padding: 12px 0px;
  }
  .scene .scene_box .right p {
    margin-top: 8px;
    color: #737783;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  .scene .scene_box .right h3 {
    margin-top: 12px;
    font-size: 20px;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
<style scoped>
@media screen and (max-width: 800px/*no*/) {
  .active {
    border-bottom: 6px solid rgb(19, 132, 252);
    background: #ffffff;
    color: #2a90ee;
  }

  h1 {
    font-family: "Arial Normal", "Arial";
    font-weight: 400;
    font-style: normal;
    font-size: 36px;
    letter-spacing: normal;
    color: #333333;
    margin-bottom: 25px;
  }
  .scene {
    width: 100%;
    margin-top: 60px;
    padding: 72px 0;
  }
  .scene h1 {
    text-align: center;
  }
  .scene .scene_box {
    display: flex;
    flex-direction: column;
  }
  .scene .scene_box .left {
    width: 600px;
    border-right: 1px solid #f0f0f5;
    display: flex;
    justify-content: center;
    margin: auto;
  }
  .scene .scene_box .left .circle {
    margin: 0px 14px 0 28px;
  }
  .scene .scene_box .left .circle .big {
    position: relative;
    background: #c9cbd2;
    opacity: 0.8;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  .scene .scene_box .left .circle .min {
    position: absolute;
    background: #c9cbd2;
    opacity: 0.5;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    top: -5px;
    left: 5px;
  }
  .scene .scene_box .left .p {
    padding: 0 6px 0 0;
    display: flex;
    align-items: center;
    height: 100px;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    width: 270px;
    /* B-Gray/B-Gray-900 */
    background: #fbfcff;
    /* B-Gray/B-Gray-800 */
  }
  .scene .scene_box .right {
    width: 600px;
    text-align: left;
    margin: auto;
  }
  .scene .scene_box .right img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  .scene .scene_box .right h2 {
    font-size: 16px; /*no*/
    color: #333333;
    padding-bottom: 10px; /*no*/
    padding: 12px 0px;
    text-align: center;
  }
  .scene .scene_box .right p {
    margin-top: 8px;
    color: #737783;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 12px; /*no*/
    line-height: 22px; /*no*/
  }
  .scene .scene_box .right h3 {
    margin-top: 12px;
    font-size: 20px;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
