<template>
  <div class="intro" :style="{ backgroundImage: 'url(' + icon + ')' }">
    <p class="title">
      {{ title }}
    </p>
    <p class="introduce">
      {{ intro }}
    </p>
  </div>
</template>
<script>
export default {
  name: "Title",
  props: {
    title: String,
    intro: String,
    icon: String,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 800px/*no*/) {
  .title {
    font-size: 28px;

    text-align: center;
  }
  .intro {
    background: no-repeat center center;
  }
  .introduce {
    font-size: 16px;
    font-weight: 400;
    color: #454a59;
  }
}
</style>
<style scoped>
@media screen and (max-width: 800px/*no*/) {
  .title {
    font-size: 18px; /*no*/
    text-align: center;
  }
  .intro {
    background: no-repeat center center;
  }
  .introduce {
    font-size: 10px; /*no*/
    font-weight: 400;
    color: #454a59;
  }
}
</style>
