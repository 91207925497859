<template>
  <div>
    <div class="content">
      <div class="standard">
        <Title :title="title" :intro="intro" :icon="icon"></Title>
        <div class="optionCard">
          <div class="optionTitle">
            <p
              v-for="(item, index) in data"
              :key="index"
              @click="num = index"
              :class="num == index ? 'active' : ''"
            >
              {{ item.title }}
            </p>
          </div>
          <div class="optionContent">
            <Editor
              style="overflow-y: hidden"
              v-model="html[num].content.content"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
            />
            <!-- <p v-html="html[num].content.content"></p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Editor } from "@wangeditor/editor-for-vue";
export default {
  name: "serviceSeIntro",
  components: {
    Editor,
  },
  props: {
    data: Array,
    title: String,
    intro: String,
    icon: String,
  },
  data() {
    return {
      num: 0,
      editor: null,
      html: this.data,
      editorConfig: { readOnly: true },
      mode: "default",
    };
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor);
      this.editor.disable(); // 一定要用 Object.seal() ，否则会报错
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 800px/*no*/) {
  .content {
    width: 1200px;
    margin: auto;
  }
  h1 {
    font-family: "Arial Normal", "Arial";
    font-weight: 400;
    font-style: normal;
    font-size: 36px;
    letter-spacing: normal;
    color: #333333;
    margin-bottom: 25px;
  }
  .active {
    border-bottom: 1px solid rgb(24, 144, 255);
    color: rgb(24, 144, 255);
  }
  .top {
    width: 100%;
    background-color: rgba(215, 215, 215, 0.9);
    padding: 95px 0 90px 0px;
  }

  .content .standard {
    margin-top: 80px;
  }
  .content .standard h1 {
    text-align: center;
  }
  .content .standard .optionCard {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .content .standard .optionCard .optionTitle {
    width: 90%;
    display: flex;
    border-bottom: 1px solid #e4e4e4;
    justify-content: center;
    margin-bottom: 40px;
    font-size: 14px; /*no*/
  }
  .content .standard .optionCard .optionTitle p {
    height: 32px;
    box-sizing: border-box;
    padding: 10px 10px;
  }
  .content .standard .optionCard .optionTitle p:hover {
    border-bottom: 1px solid #1890ff;
    color: #1890ff;
  }
  .content .standard .optionCard .optionContent {
    width: 90%;
  }
}
</style>
<style scoped>
@media screen and (max-width: 800px/*no*/) {
  .content {
    width: 100%;
    height: auto;
  }
  h1 {
    font-family: "Arial Normal", "Arial";
    font-weight: 400;
    font-style: normal;
    font-size: 36px;
    letter-spacing: normal;
    color: #333333;
    margin-bottom: 25px;
  }
  .active {
    border-bottom: 1px solid rgb(24, 144, 255);
    color: rgb(24, 144, 255);
  }
  .top {
    width: 100%;
    background-color: rgba(215, 215, 215, 0.9);
    padding: 95px 0 90px 0px;
  }

  .content .standard {
    margin-top: 80px;
  }
  .content .standard h1 {
    text-align: center;
  }
  .content .standard .optionCard {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .content .standard .optionCard .optionTitle {
    width: 85%;
    border-bottom: 1px solid #e4e4e4;
    white-space: nowrap;
    margin-bottom: 40px;
    font-size: 14px; /*no*/
    overflow: scroll;
    text-align: center;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  .content .standard .optionCard .optionTitle p {
    height: 32px; /*no*/
    box-sizing: border-box;
    padding: 10px; /*no*/
    display: inline-block;
  }
  .content .standard .optionCard .optionTitle p:hover {
    border-bottom: 1px solid #1890ff;
    color: #1890ff;
  }
  .content .standard .optionCard .optionContent {
    width: 600px;
  }
}
</style>
