import { login, loginagin } from "@/api/login";
import {
  setToken,
  setUserInfo,
  clearToken,
  clearUserInfo,
} from "@/utils/setToken";
import { getNavAll } from "@/api/home";
const state = {
  token: JSON.parse(localStorage.getItem("token")) || "",
  userInfo: JSON.parse(localStorage.getItem("userInfo")) || "",
  username: "",
  menu: [],
};
const mutations = {
  SET_TOKEN(state, payload) {
    state.token = payload;
    setToken(payload);
  },
  SET_USERINFO(state, payload) {
    state.userInfo = payload;
    setUserInfo(payload);
  },
  SET_MENU(state, payload) {
    state.menu = payload;
  },
};
const actions = {
  async getToken({ commit }, info) {
    await login(info).then(
      (res) => {
        commit("SET_TOKEN", res.token);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },

  async getAgain({ commit }) {
    let re = await loginagin();
    if (re) {
      commit("SET_USERINFO", re);
    } else {
      return new Promise.reject();
    }
  },

  async loginOut({ commit }) {
    commit("SET_TOKEN", "");
    commit("SET_USERINFO", "");
    clearToken();
    clearUserInfo();
  },
  async getMenu({ commit }) {
    let re = await getNavAll();

    commit("SET_MENU", re);
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
