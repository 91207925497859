import requestLocal from "@/utils/requestlocal";
export const getSwiperList = (params) => {
  return requestLocal.get(`portal/slide/list`, params);
};
export const getNavList = (query) => {
  return requestLocal({
    url: "/portal/category/treeList",
    method: "get",
    params: query,
  });
};
export const getNavAll = () => {
  return requestLocal({
    url: "/portal/category/topList",
    method: "get",
  });
};
export const getHomeList = (query) => {
  return requestLocal({
    url: "/portal/category/list",
    method: "get",
    params: query,
  });
};

// 通过id查询内容
export const getContent = (id) => {
  return requestLocal.get(`/portal/content/${id}`);
};
//下一页
export const getNext = (query) => {
  return requestLocal({
    url: "/portal/content/PreviousOrNext",
    method: "get",
    params: query,
  });
};
// 搜索
export const getSearch = (query) => {
  return requestLocal({
    url: "/portal/content/listAll",
    method: "get",
    params: query,
  });
};
//使用帮助搜索
export const Search = (query) => {
  return requestLocal({
    url: "/common/searchDoc",
    method: "get",
    params: query,
  });
};
//收集信息
export const getInfo = (json) => {
  return requestLocal({
    url: "/contact/us",
    method: "post",
    data: json,
  });
};
//收集信息
export const getHomeInfo = (json) => {
  return requestLocal({
    url: "/common/us ",
    method: "post",
    data: json,
  });
};
export const getDeal = (id) => {
  return requestLocal({
    url: `/bus/deal/${id}`,
    method: "get",
  });
};
