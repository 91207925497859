<template>
  <div class="top">
    <img :src="data[0].coverPic" alt="" class="topimg" />
    <div class="content">
      <div class="intro" :style="{ backgroundImage: 'url(' + icon + ')' }">
        <p class="title">
          {{ title }}
        </p>
        <p class="introduce">
          {{ intro }}
        </p>
      </div>
      <p>
        {{ data[0].content.content }}
      </p>
      <button
        v-for="button in data[0].content.button"
        :key="button.name"
        @click="
          window.$bus.$emit('dialog', { flag: true, to: '/center/reinforce' })
        "
      >
        {{ button.name }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "productOneIntro",
  props: {
    data: Array,
    title: String,
    intro: String,
    icon: String,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 800px/*no*/) {
  .topimg {
    width: 100%;
    min-width: 1200px;
    max-height: 600px;
  }
  .top {
    min-width: 1200px;
    width: 100%;
    position: relative;
    min-height: 300px;
  }
  h1 {
    font-family: "Arial Normal", "Arial";
    font-weight: 400;
    font-style: normal;
    font-size: 36px;
    letter-spacing: normal;
    color: #333333;
    margin-bottom: 25px;
  }
  .top p {
    margin-top: 20px;
    width: 642px;
    font-size: 14px;
    line-height: 26px;
    font-family: "Arial Normal", "Arial";
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    color: #333333;
  }
  .top button {
    margin-top: 50px;
    width: 120px;
    height: 40px;
    background: inherit;
    background-color: #ffffff;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: #797979;
    border-radius: 0px;
  }
  .content {
    position: absolute;
    text-align: left;
    left: 300px;
    height: 300px;
    top: 150px;
    .title {
      font-size: 36px;
      text-align: left;
    }
    .intro {
      background: no-repeat center center;
    }
    .introduce {
      font-size: 16px;
      font-weight: 400;
      color: #454a59;
    }
  }
}
</style>
<style scoped>
@media screen and (max-width: 800px/*no*/) {
  .top {
    width: 100%;
    position: relative;
    min-height: 300px;
  }
  .topimg {
    width: 100%;
    max-height: 600px;
  }
  .top p {
    margin-top: 20px;
    width: 70%;
    font-size: 9px; /*no*/
    line-height: 14px; /*no*/
    font-family: "Arial Normal", "Arial";
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    color: #333333;
    max-height: 600px;
  }
  .top button {
    margin-top: 50px;
    width: 120px;
    height: 40px;
    background: inherit;
    background-color: #ffffff;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: #797979;
    border-radius: 0px;
  }
  .content {
    position: absolute;
    text-align: left;
    left: 10%;
    height: 300px;
    top: 1%;
  }
  .content .title {
    font-size: 16px; /*no*/
    text-align: left;
  }
  .content .intro {
    background: no-repeat center center;
    margin-bottom: 0px !important;
  }
  .content .introduce {
    font-size: 16px;
    font-weight: 400;
    color: #454a59;
  }
}
</style>
