//引入全局组件
import Vue from "vue"; // 引入vue

// 处理首字母大写 abc => Abc
function changeStr(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * 自动引入globalComponents文件夹下所有vue文件
 * require.context(directory, useSubdirectories = false, regExp = /^.//);
 * @param {String} directory 读取文件的路径
 * @param {Boolean} directory 是否遍历文件的子目录
 * @param {regExp} regExp  匹配文件的正则表达式
 */

const globalComponent = require.context("./globalComponents", true, /\.vue$/);
globalComponent.keys().forEach((fileName) => {
  const componentName = changeStr(
    fileName.replace(/[^\\\\/]*[\\\\/]+/g, "").replace(/\.\w+$/, "") // ./child1.vue => child1
  );
  const config = globalComponent(fileName);
  Vue.component(componentName, config.default || config); // 动态注册该目录下的所有.vue文件
});

// /**
//  * 自动引入modules文件夹下所有js文件
//  */

// const modulesFiles = require.context("./modules", true, /\.js$/);
// // you do not need `import app from './modules/app'`
// modulesFiles.keys().forEach((fileName) => {
//   const moduleName = fileName
//     .replace(/[^\\\/]*[\\\/]+/g, "")
//     .replace(/\.\w+$/, ""); // set './app.js' => 'app'
//   const config = modulesFiles(fileName);
//   Vue.prototype[moduleName] = config.default || config; //原型链挂载js文件
// });
