export function setToken(token) {
  localStorage.setItem("token", JSON.stringify(token));
}
export function setUserInfo(userinfo) {
  localStorage.setItem("userInfo", JSON.stringify(userinfo));
}
export function clearToken() {
  localStorage.removeItem("token");
}
export function clearUserInfo() {
  localStorage.removeItem("userInfo");
}
