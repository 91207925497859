<template>
  <div id="app"><router-view></router-view></div>
</template>
<script>
import "@/styles/common.css";
export default {
  name: "App",
  components: {},
  metaInfo() {
    return {
      title: this.$route.meta.seoTitle || this.$route.meta.title,
      meta: [
        {
          charset: "utf-8",
        },
        {
          name: "viewport",
          content:
            "width=device-width, initial-scale=1.0,minimum-scale=1.0, maximum-scale=1.0, user-scalable=no",
        },

        {
          name: "keyWords",
          content: this.$route.meta.seoKey, //变量或字符串
        },
        {
          name: "description",
          content: this.$route.meta.seoValue,
        },
      ],
    };
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
#app {
  height: 100%;
}
::v-deep .w-e-scroll {
  background-color: #f4f9fd !important;
}
</style>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
.title {
  text-align: center;
  font-style: normal;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 0.1458333333333333rem;
  line-height: 36px;
  /* identical to box height, or 129% */
  text-align: center;
  /* B-Gray/B-Gray */
  color: #161d30;
}
.introduce {
  margin: 16px 0 0 0;
  text-align: center;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: #868686;
  font-family: "PingFang SC";
  line-height: 24px;
}

@media screen and (max-width: 800px/*no*/) {
  .content {
    width: 100%;
    margin: auto;
  }
  img {
    max-width: 100%;
  }
  .intro {
    margin-bottom: 32px;
  }
}
@media screen and (min-width: 800px/*no*/) {
  .content {
    width: 1200px;
    margin: auto;
  }
  .intro {
    margin-bottom: 40px;
  }
}
.w-e-scroll {
  background-color: #fbfcff;
}

.pagination {
  /* width: 85%; */
  /* position: fixed;
  bottom: 40px; */
  margin: 16px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rest {
  min-width: 72px;
  height: 0.1666666666666667rem;
  box-sizing: border-box;
  border: 1px solid #2a90ee !important;
  border-radius: 16px;
  text-align: center;
  color: #2a90ee;
  font-size: 14px;
  line-height: 0.1666666666666667rem;
}
.rest:hover {
  cursor: pointer;
}
.Auth {
  padding: 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
}
.Auth .el-icon-warning {
  font-size: 40px;
  color: #2a90ee;
}
.el-icon-back {
  color: #2a90ee;
}
.button {
  font-size: 0.0833333333333333rem !important;
}
</style>
