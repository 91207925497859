<template>
  <div class="content">
    <div class="guard">
      <Title :title="title" :intro="intro" :icon="icon"></Title>
      <div class="home_product">
        <div
          class="product_box"
          v-for="item in data"
          :key="item.id"
          :style="divWidth()"
        >
          <img :src="item.coverPic" alt="" />
          <h1>{{ item.title }}</h1>
          <p :style="pWidth()" v-html="item.content.content"></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  // 首页-全程守护产品模块
  name: "homeProduct",
  props: {
    data: Array,
    title: String,
    intro: String,
    icon: String,
  },
  methods: {
    divWidth() {
      let width = (1200 - (this.data.length - 1) * 24) / this.data.length;

      return `width:${width}px`;
    },
    pWidth() {
      let width = (1200 - (this.data.length - 1) * 24) / this.data.length - 48;

      return `width:${width}px`;
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 800px/*no*/) {
  .guard .home_product {
    display: flex;
  }
  .guard .home_product .product_box {
    margin-right: 24px;
  }

  .guard .home_product .product_box:last-of-type {
    margin-right: 0px;
  }
  .guard {
    padding: 72px 0px;
  }
  .guard .home_product .product_box h1 {
    margin: 16px 0px;
    font-size: 20px;
    font-family: PingFangSC-Medium-Regular, PingFangSC-Medium;
    font-weight: 400;
    color: #161d30;
    line-height: 28px;
    text-align: center;
  }
  .guard .home_product .product_box img {
    margin: 24px auto;
    display: block;
    text-align: center;
    width: 112px;
    height: 112px;
  }
  .guard .home_product .product_box p {
    margin: 0px 24px 46px 24px;
    font-family: "PingFang SC";
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: #737783;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
    display: -webkit-box;
    line-height: 22px;
  }
}
</style>
<style scoped>
@media screen and (max-width: 800px/*no*/) {
  .guard .product_box {
    margin-top: 40px;
    width: 600px !important;
    margin: auto;
  }
  .guard .home_product .product_box p {
    width: 100% !important;
    line-height: 22px; /*no*/
    margin: 0 !important;
  }
  .guard {
    padding: 72px 0px;
  }
  .guard .home_product .product_box h1 {
    margin: 16px 0px;
    font-size: 14px; /*no*/
    font-family: PingFangSC-Medium-Regular, PingFangSC-Medium;
    font-weight: 400;
    color: #161d30;
    line-height: 28px;
    text-align: center;
  }
  .guard .home_product .product_box img {
    margin: 24px auto;
    display: block;
    text-align: center;
    width: 112px;
    height: 112px;
  }
  .guard .home_product .product_box p {
    margin: 0px 24px 46px 24px;
    font-family: "PingFang SC";
    font-weight: 400;
    font-style: normal;
    font-size: 12px; /*no*/
    color: #737783;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
    display: -webkit-box;
    line-height: 22px; /*no*/
  }
}
</style>
