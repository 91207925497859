<template>
  <!-- <InBody> -->
  <el-pagination
    background
    layout="total,sizes,prev, pager, next,jumper"
    :total="total"
    :page-sizes="[10, 20, 50, 100]"
    :page-size="10"
    @size-change="changeSize"
    @current-change="changeNum"
  >
  </el-pagination>
  <!-- </InBody> -->
</template>
<script>
export default {
  name: "Pagination",
  props: {
    total: Number,
    pageSize: Number,
    pageNum: Number,
  },
  data() {
    return {};
  },
  methods: {
    changeNum(val) {
      this.$emit("changeNum", val);
    },
    changeSize(val) {
      this.$emit("changeSize", val);
    },
  },
};
</script>

<style scoped>
::v-deep .el-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
::v-deep .el-input__inner {
  height: 28px !important;
  width: 100px;
}
::v-deep .el-input {
  width: 100px !important;
}
</style>
