import requestLocal from "@/utils/requestlocal";
export const login = (params) => {
  return requestLocal.post("user/login", params);
};
export const loginagin = () => {
  return requestLocal({
    url: "/GongZhong/getCurrentMember",
    method: "get",
  });
};
export const loginCode = (query) => {
  return requestLocal({
    url: `/user/getPhoneVCode.do`,
    method: "get",
    params: query,
  });
};
export const registerCode = (phone) => {
  return requestLocal.get(
    `phoneVCode/getPhoneVCode.do?phone=${phone}&vType=REGISTER_CODE_TYPE`
  );
};
export const editPassword = (query) => {
  return requestLocal({
    url: `/portal/register/registerPhone`,
    method: "get",
    params: query,
  });
};
