<template>
  <div class="content">
    <div class="swiper-container" ref="swiper2">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="item in data" :key="item.id">
          <img :src="item.coverPic" alt="" class="img" />
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <!-- <div class="swiper-pagination"></div> -->
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
import "swiper/css/swiper.css";
export default {
  name: "homeCuPic",
  props: {
    data: Array,
    title: String,
    intro: String,
    icon: String,
  },
  data() {
    return {
      slidesPerView: 6,
      slidesPerGroup: 2,
    };
  },
  computed: {},
  methods: {
    swiperInit() {
      new Swiper(this.$refs.swiper2, {
        // direction: 'vertical', // 垂直切换选项
        loop: true, // 循环模式选项
        // 如果需要分页器
        slidesPerView: this.slidesPerView,
        slidesPerGroup: this.slidesPerGroup,
        slidesPerColumnFill: "row",
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        spaceBetween: 40,
        speed: 4000,
        autoplay: {
          delay: 0, //自动切换的时间间隔
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
      });
    },
  },
  mounted() {
    const width = window.screen.width;
    if (width < 800) {
      this.slidesPerView = 3;
      this.slidesPerGroup = 1;
    }
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          if (this.data.length <= 0) return;
          this.swiperInit();
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.customer_logo {
  padding: 0 0 72px 0;
  justify-content: space-around;
  display: flex;
  flex-wrap: wrap;
}
.customer_logo .logo {
  margin-top: 10px;
}

.swiper-container .swiper-wrapper {
  -webkit-transition-timing-function: linear !important; /*之前是ease-out*/
  -moz-transition-timing-function: linear !important;
  -ms-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}
.swiper-wrapper {
  display: flex;
  align-items: center;
}
.img {
  width: 200px;
  height: 100px;
}
</style>
<style scoped>
@media screen and (max-width: 800px/*no*/) {
  .customer_logo {
    padding: 0 0 72px 0;
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;
  }
  .customer_logo .logo {
    margin-top: 10px;
  }

  .swiper-container .swiper-wrapper {
    -webkit-transition-timing-function: linear !important; /*之前是ease-out*/
    -moz-transition-timing-function: linear !important;
    -ms-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
  }
  .swiper-wrapper {
    display: flex;
    align-items: center;
  }
  .img {
    width: 200px;
    height: 100px;
  }
}
</style>
