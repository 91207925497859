<template>
  <div class="content">
    <div class="customer">
      <Title :title="title" :intro="intro" :icon="icon"></Title>
      <div class="customer_box">
        <div
          class="customer_data"
          v-for="item in pageView[0]?.contentList"
          :key="item.id"
        >
          <countTo
            :endVal="+item.content.content"
            :duration="3000"
            decimal=","
            class="num"
          ></countTo>
          <p>{{ item.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getHomeList } from "@/api/home";
import countTo from "vue-count-to";
export default {
  name: "homeCu",
  props: {
    data: Array,
    title: String,
    intro: String,
    icon: String,
  },
  components: { countTo },
  data() {
    return {
      pageView: "",
    };
  },
  methods: {
    async getHome() {
      let params = {
        isInside: "1",
        parentId: " 1",
        cateModel: "homeCu",
      };
      let res = await getHomeList(params);
      this.pageView = res;
    },
  },
  mounted() {
    this.getHome();
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 800px) {
  .customer {
    padding: 72px 0 0 0;
  }
  .customer .customer_box {
    display: flex;
    justify-content: space-around;
  }
  .customer .customer_box .customer_data {
    margin: 40px 0px 60px 0;
  }
  .customer .customer_box .customer_data .num {
    font-size: 40px;
    font-family: "Montserrat-ExtraBold", "Montserrat";
    font-weight: 800;
    color: #2687e0;
    display: flex;
    justify-content: center;
  }
  .customer .customer_box .customer_data p {
    font-size: 18px;
    color: #7f7f7f;
    text-align: center;
    line-height: 24px;
    margin-top: 8px;
  }
}
</style>
<style scoped>
@media screen and (max-width: 800px/*no*/) {
  .customer {
    padding: 72px 0 0 0;
  }
  .customer .customer_box {
    display: flex;
    justify-content: space-around;
  }
  .customer .customer_box .customer_data {
    margin: 40px 0px 60px 0;
  }
  .customer .customer_box .customer_data .num {
    font-size: 40px; /*no*/
    font-family: "Montserrat-ExtraBold", "Montserrat";
    font-weight: 800;
    color: #2687e0;
    display: flex;
    justify-content: center;
  }
  .customer .customer_box .customer_data p {
    font-size: 18px;
    color: #7f7f7f;
    text-align: center;
    line-height: 24px;
    margin-top: 8px;
  }
}
</style>
