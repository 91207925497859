<template>
  <div class="content">
    <Title :title="title" :intro="intro" :icon="icon"></Title>
    <div class="package">
      <div class="package_content" v-for="(item, index) in data" :key="item.id">
        <div v-if="index % 2 === 0" class="left">
          <div class="leftText">
            <h1>
              {{ item.title }}
              <span>{{ item.content.trans }}</span>
            </h1>
            <i></i>
            <h2>{{ item.content.intro }}</h2>
            <p v-html="item.content.content"></p>
            <div v-for="button in item.content.button" :key="button.name">
              {{ button.name }}
            </div>
          </div>
          <div class="rightImg">
            <img :src="item.coverPic" alt="暂无" />
          </div>
        </div>
        <div v-if="index % 2 !== 0" class="right">
          <div class="leftImg">
            <img :src="item.coverPic" alt="暂无" />
          </div>
          <div class="rightText">
            <h1>
              {{ item.title }} <span>{{ item.content.trans }}</span>
            </h1>
            <i></i>
            <h2>{{ item.content.intro }}</h2>
            <p v-html="item.content.content"></p>
            <div v-for="button in item.content.button" :key="button.name">
              {{ button.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  //首页-产品介绍模块
  name: "homeInPro",
  props: {
    data: Array,
    title: String,
    intro: String,
    icon: String,
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 800px/*no*/) {
  .package .package_content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 72px 0px;
  }
  .package .package_content .leftImg img {
    width: 548px;
    overflow: hidden;
    height: 440px;
  }

  .package .package_content .rightImg img {
    width: 588px;
    overflow: hidden;
    height: 440px;
  }
  .package .package_content .left,
  .right {
    display: flex;
    justify-content: space-between;
  }

  .package .package_content .leftText,
  .rightText {
    width: 580px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .package .package_content .leftText h1 {
    font-family: "Arial Normal", "Arial";
    font-weight: 400;
    font-style: normal;
    font-size: 28px;
  }
  .package .package_content .leftText h1 span {
    font-size: 20px;
    font-family: "Montserrat-Light", "Montserrat";
    font-weight: 300;
    color: #9da1aa;
    line-height: 28px;
  }
  .package .package_content .leftText i {
    display: block;
    margin: 26px 0px;
    margin-top: 20px;
    width: 12px;
    height: 2px;
    background: inherit;
    background-color: #918c94;
  }
  .package .package_content .leftText h2 {
    font-family: "微软雅黑";
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    color: #666666;
    text-align: left;
    line-height: 26px;
  }
  .package .package_content .leftText p {
    margin-top: 24px;
    width: 400px;
    color: #777777;
    line-height: 26px;
    font-family: "微软雅黑";
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
  }
  .package .package_content .leftText div:first-of-type {
    margin-top: 60px;
    justify-content: center;
    width: 96px;
    height: 40px;
    background: inherit;
    background-color: #409eff;
    border: none;
    border-radius: 20px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #ffffff;
    font-family: "微软雅黑";
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    line-height: 40px;
  }
  .package .package_content .leftText div:last-of-type {
    margin-top: 20px;
    width: 96px;
    height: 40px;
    background: inherit;
    background-color: #ffffff;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: #d7d7d7;
    border-radius: 24px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #000000;
    text-align: center;
    font-family: "微软雅黑";
    font-weight: 400;
    line-height: 40px;
  }

  .package .package_content .rightText h1 {
    font-family: "Arial Normal", "Arial";
    font-weight: 400;
    font-style: normal;
    font-size: 28px;
  }
  .package .package_content .rightText h1 span {
    font-size: 20px;
    font-family: "Montserrat-Light", "Montserrat";
    font-weight: 300;
    color: #9da1aa;
    line-height: 28px;
  }
  .package .package_content .rightText i {
    display: block;
    margin: 26px 0px;
    margin-top: 20px;
    width: 12px;
    height: 2px;
    background: inherit;
    background-color: #918c94;
  }
  .package .package_content .rightText h2 {
    font-family: "微软雅黑";
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    color: #666666;
    text-align: left;
    line-height: 26px;
  }
  .package .package_content .rightText p {
    margin-top: 24px;
    width: 400px;
    color: #777777;
    line-height: 26px;
    font-family: "微软雅黑";
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
  }
  .package .package_content .rightText div:first-of-type {
    margin-top: 60px;
    justify-content: center;
    width: 96px;
    height: 40px;
    background: inherit;
    background-color: #409eff;
    border: none;
    border-radius: 20px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #ffffff;
    font-family: "微软雅黑";
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    line-height: 40px;
  }
  .package .package_content .rightText div:last-of-type {
    margin-top: 20px;
    width: 96px;
    height: 40px;
    background: inherit;
    background-color: #ffffff;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: #d7d7d7;
    border-radius: 24px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #000000;
    text-align: center;
    font-family: "微软雅黑";
    font-weight: 400;
    line-height: 40px;
  }
}
</style>
<style scoped>
@media screen and (max-width: 800px/*no*/) {
  .package .package_content div {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .package .package_content .right {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
  }
  .package .package_content .leftImg img {
    width: 600px;
    overflow: hidden;
    height: 440px;
  }

  .package .package_content .rightImg img {
    width: 600px;
    overflow: hidden;
    height: 440px;
  }
  .package .package_content .left,
  .right {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .package .package_content .leftText,
  .rightText {
    width: 600px;
    display: flex;
    flex-direction: column;
  }
  .package .package_content .leftText h1 {
    font-family: "Arial Normal", "Arial";
    font-weight: 400;
    font-style: normal;
    font-size: 14px; /*no*/
    text-align: center;
  }
  .package .package_content .leftText h1 span {
    font-size: 13px; /*no*/
    font-family: "Montserrat-Light", "Montserrat";
    font-weight: 300;
    color: #9da1aa;
    line-height: 28px;
  }
  .package .package_content .leftText i {
    display: block;
    margin: 26px 0px;
    margin-top: 20px;
    width: 12px;
    height: 2px;
    background: inherit;
    background-color: #918c94;
    margin-left: calc((100% - 12px) / 2);
  }
  .package .package_content .leftText h2 {
    font-family: "微软雅黑";
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    color: #666666;
    text-align: left;
    line-height: 26px;
  }
  .package .package_content .leftText p {
    width: 100%;
    text-align: left;
    margin-top: 24px;
    color: #777777;
    line-height: 22px; /*no*/
    font-family: "微软雅黑";
    font-weight: 400;
    font-style: normal;
    font-size: 12px; /*no*/
  }
  .package .package_content .leftText div:first-of-type {
    margin-top: 60px;
    justify-content: center;
    width: 96px;
    height: 40px;
    background: inherit;
    background-color: #409eff;
    border: none;
    border-radius: 20px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #ffffff;
    font-family: "微软雅黑";
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    line-height: 40px;
  }
  .package .package_content .leftText div:last-of-type {
    margin-top: 20px;
    width: 96px;
    height: 40px;
    background: inherit;
    background-color: #ffffff;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: #d7d7d7;
    border-radius: 24px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #000000;
    text-align: center;
    font-family: "微软雅黑";
    font-weight: 400;
    line-height: 40px;
  }

  .package .package_content .rightText h1 {
    font-family: "Arial Normal", "Arial";
    font-weight: 400;
    font-style: normal;
    font-size: 14px; /*no*/
    text-align: center;
  }
  .package .package_content .rightText h1 span {
    font-size: 13px; /*no*/
    font-family: "Montserrat-Light", "Montserrat";
    font-weight: 300;
    color: #9da1aa;
    line-height: 28px;
  }
  .package .package_content .rightText i {
    display: block;
    margin: 26px 0px;
    margin-top: 20px;
    width: 12px;
    height: 2px;
    background: inherit;
    background-color: #918c94;
    margin-left: calc((100% - 12px) / 2);
  }
  .package .package_content .rightText h2 {
    font-family: "微软雅黑";
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    color: #666666;
    text-align: left;
    line-height: 26px;
  }
  .package .package_content .rightText p {
    margin-top: 24px;
    line-height: 22px; /*no*/
    color: #777777;
    font-family: "微软雅黑";
    font-weight: 400;
    font-style: normal;
    font-size: 12px; /*no*/
  }
  .package .package_content .rightText div:first-of-type {
    margin-top: 60px;
    justify-content: center;
    width: 96px;
    height: 40px;
    background: inherit;
    background-color: #409eff;
    border: none;
    border-radius: 20px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #ffffff;
    font-family: "微软雅黑";
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    line-height: 40px;
  }
  .package .package_content .rightText div:last-of-type {
    margin-top: 20px;
    width: 96px;
    height: 40px;
    background: inherit;
    background-color: #ffffff;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: #d7d7d7;
    border-radius: 24px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #000000;
    text-align: center;
    font-family: "微软雅黑";
    font-weight: 400;
    line-height: 40px;
  }
}
</style>
