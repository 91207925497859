import Vue from "vue";
import Vuex from "vuex";
import user from "@/store/modules/user";
import app from "./modules/app";
import settings from "./modules/settings";
import getters from "./getters";
Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    user,
    app,
    settings,
  },
  getters,
});
