<template>
  <div class="content">
    <div class="package">
      <Title :title="title" :intro="intro" :icon="icon"></Title>
      <div
        :class="data.length > 1 ? 'package_content more' : 'package_content'"
        v-for="(item, index) in data"
        :key="item.id"
      >
        <div v-if="index % 2 === 0" class="one">
          <div class="leftText">
            <div class="text">
              <h1>
                {{ item.title }}
              </h1>
              <i></i>

              <p>
                {{ item.content.content }}
              </p>
              <div class="button" v-if="item.content.button.length">
                <h2>推荐以下服务</h2>
                <div class="button_group">
                  <div v-for="button in item.content.button" :key="button.name">
                    {{ button.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="rightImg">
            <img :src="item.coverPic" alt="暂无" />
          </div>
        </div>
        <div v-if="index % 2 !== 0" class="two">
          <div class="leftImg">
            <img :src="item.coverPic" alt="暂无" />
          </div>
          <div class="rightText">
            <div class="text">
              <h1>
                {{ item.title }}
              </h1>
              <i></i>
              <p>
                {{ item.content.content }}
              </p>
              <div class="button" v-if="item.content.button.length">
                <h2>推荐以下服务</h2>
                <div class="button_group">
                  <div v-for="button in item.content.button" :key="button.name">
                    {{ button.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  //解决方案-行业背景模块
  name: "solutionBack",
  props: {
    data: Array,
    title: String,
    intro: String,
    icon: String,
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 800px/*no*/) {
  .more {
    padding: 72px 0px;
  }
  .package {
    padding: 72px 0px;
  }
  .package .package_content .one,
  .two {
    display: flex;
  }
  .package .package_content .rightImg,
  .leftImg {
    width: 588px;
    overflow: hidden;
  }
  .package .package_content .one .rightImg img {
    width: 588px;
    height: 440px;
    object-fit: contain;
  }
  .package .package_content .one .leftText {
    width: 612px;
    margin-right: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .package .package_content .one .leftText .text {
    height: 290px;
  }
  .package .package_content .one .leftText .text h1 {
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }
  .package .package_content .one .leftText .text h1 span {
    font-size: 20px;
    font-family: "Montserrat-Light", "Montserrat";
    font-weight: 300;
    color: #9da1aa;
    line-height: 28px;
  }
  .package .package_content .one .leftText .text i {
    display: block;
    margin: 24px 0px;
    margin-top: 20px;
    width: 12px;
    height: 2px;
    background: inherit;
    background-color: #918c94;
  }
  .package .package_content .one .leftText .text h2 {
    font-family: "微软雅黑";
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #666666;
    text-align: left;
    line-height: 26px;
  }
  .package .package_content .one .leftText .text p {
    color: #777777;
    line-height: 26px;
    font-family: "微软雅黑";
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    display: -webkit-box;
  }
  .package .package_content .one .leftText .button h2 {
    margin: 24px 0 12px 0;
  }
  .package .package_content .one .leftText .button .button_group {
    display: flex;
  }
  .package .package_content .one .leftText .button .button_group div {
    margin-right: 12px;
    box-sizing: border-box;
    width: 120px;
    height: 32px;
    padding: 5px 16px;
    border: 1px solid #2a90ee;
    border-radius: 24px;
    font-family: "PingFang SC";
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #2a90ee;
    text-align: center;
  }
  .package .package_content .one .leftText .button .button_group div:hover {
    cursor: pointer;
  }

  .package .package_content .two .rightText {
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 580px;
  }
  .package .package_content .two .rightText .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 290px;
  }
  .package .package_content .two .rightText .text h1 {
    font-family: "Arial Normal", "Arial";
    font-weight: 400;
    font-style: normal;
    font-size: 24px;
  }
  .package .package_content .two .rightText .text h1 span {
    font-size: 20px;
    font-family: "Montserrat-Light", "Montserrat";
    font-weight: 300;
    color: #9da1aa;
    line-height: 28px;
  }
  .package .package_content .two .rightText .text i {
    display: block;
    margin: 24px 0px;
    margin-top: 20px;
    width: 12px;
    height: 2px;
    background: inherit;
    background-color: #918c94;
  }
  .package .package_content .two .rightText .text h2 {
    font-family: "微软雅黑";
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #666666;
    text-align: left;
    line-height: 26px;
  }
  .package .package_content .two .rightText .text p {
    color: #777777;
    line-height: 26px;
    font-family: "微软雅黑";
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    display: -webkit-box;
  }
  .package .package_content .two .rightText .text .button h2 {
    margin: 24px 0 12px 0;
  }
  .package .package_content .two .rightText .text .button .button_group {
    display: flex;
  }
  .package .package_content .two .rightText .text .button .button_group div {
    margin-right: 12px;
    box-sizing: border-box;
    width: 120px;
    height: 32px;
    padding: 5px 16px;
    border: 1px solid #2a90ee;
    border-radius: 24px;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #2a90ee;
    text-align: center;
  }
  .package
    .package_content
    .two
    .rightText
    .text
    .button
    .button_group
    div:hover {
    cursor: pointer;
  }
}
</style>
<style scoped>
@media screen and (max-width: 800px/*no*/) {
  .package {
    padding: 72px 0px;
  }

  .package .package_content .two {
    display: flex;
    flex-direction: column-reverse;
  }
  .package .package_content .rightImg,
  .leftImg {
    width: 600px;
    overflow: hidden;
    margin: auto;
    max-height: 440px;
  }
  .package .package_content img {
    width: 600px;
    height: 440px;
    object-fit: contain;
  }
  .package .package_content .one .leftText {
    width: 600px;
    margin-right: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
  }

  .package .package_content .one .leftText .text h1 {
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 500;
    font-size: 16px; /*no*/
    line-height: 32px;
    text-align: center;
  }

  .package .package_content .one .leftText .text i {
    display: block;
    margin: 24px auto;
    width: 12px;
    height: 2px;
    background: inherit;
    background-color: #918c94;
  }

  .package .package_content .one .leftText .text p {
    color: #777777;
    line-height: 22px; /*no*/
    font-family: "微软雅黑";
    font-weight: 400;
    font-style: normal;
    font-size: 12px; /*no*/
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    display: -webkit-box;
  }
  .package .package_content .one .leftText .button h2 {
    margin: 24px 0 12px 0;
  }
  .package .package_content .one .leftText .button .button_group {
    display: flex;
    justify-content: space-between;
  }
  .package .package_content .one .leftText .button .button_group div {
    margin: 0 12px 12px 0;
    box-sizing: border-box;

    height: 32px;
    padding: 5px 16px;
    border: 1px solid #2a90ee;
    border-radius: 24px;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #2a90ee;
    text-align: center;
  }
  .package .package_content .one .leftText .button .button_group div:hover {
    cursor: pointer;
  }

  .package .package_content .two .rightText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 600px;
    margin: auto;
  }
  .package .package_content .two .rightText .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .package .package_content .two .rightText .text h1 {
    font-family: "Arial Normal", "Arial";
    font-weight: 400;
    font-style: normal;
    font-size: 16px; /*no*/
    text-align: center;
  }
  .package .package_content .two .rightText .text i {
    display: block;
    margin: 24px auto;
    width: 12px;
    height: 2px;
    background: inherit;
    background-color: #918c94;
  }

  .package .package_content .two .rightText .text p {
    color: #777777;
    line-height: 22px; /*no*/
    font-family: "微软雅黑";
    font-weight: 400;
    font-style: normal;
    font-size: 12px; /*no*/
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    display: -webkit-box;
  }
  .package .package_content .two .rightText .text .button h2 {
    margin: 24px 0 12px 0;
  }
  .package .package_content .two .rightText .text .button .button_group {
    display: flex;
    justify-content: space-between;
  }
  .package .package_content .two .rightText .text .button .button_group div {
    margin: 0 12px 12px 0;
    box-sizing: border-box;

    height: 32px;
    padding: 5px 16px;
    border: 1px solid #2a90ee;
    border-radius: 24px;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #2a90ee;
    text-align: center;
  }
}
</style>
