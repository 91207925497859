<template>
  <div class="content">
    <div class="news">
      <Title :title="title" :intro="intro" :icon="icon"></Title>
      <div class="news_box">
        <div
          class="news_content"
          v-for="item in data"
          :key="item.title"
          :style="divWidth()"
        >
          <img :src="item.coverPic" alt="" :style="divWidth()" />
          <div class="news_text">
            <span>{{ item.title }}</span>
            <p>
              {{ item.content.content }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  // 首页-新闻模块类似
  name: "homeNews",
  props: {
    data: Array,
    title: String,
    intro: String,
    icon: String,
    id: Number,
  },
  methods: {
    divWidth() {
      let width = (1200 - (this.data.length - 1) * 24) / this.data.length;
      return `width:${width}px`;
    },
    pWidth() {
      let width = (1200 - (this.data.length - 1) * 24) / this.data.length - 48;
      return `width:${width}px`;
    },
    toMore(id) {
      this.$router.push({
        path: `/home/more/${id}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 800px/*no*/) {
  .news {
    padding: 72px 0px;
  }
  .news .more {
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */
    text-align: right;
    /* B-Gray/B-Gray-600 */
    color: #9da1aa;
    margin-bottom: 10px;
  }
  .news .more:hover {
    cursor: pointer;
  }
  .news .news_box {
    display: flex;
    justify-content: space-between;
  }
  .news .news_box .news_content {
    height: 422px;
    display: flex;
    flex-direction: column;
  }
  .news .news_box .news_content img {
    height: 200px;
  }
  .news .news_box .news_content .news_text {
    background-color: #ffffff;
    border: 1px solid #f0f0f5;
    box-sizing: border-box;
  }
  .news .news_box .news_content .news_text span {
    width: 100%;
    margin: 16px 0px;
    display: inline-block;
    padding: 0 24px;
    font-size: 16px;
    font-family: PingFangSC-Medium-Regular, PingFangSC-Medium;
    font-weight: 400;
    color: #161d30;
    line-height: 24px;
    height: 48px;
  }
  .news .news_box .news_content .news_text span:hover {
    cursor: pointer;
  }
  .news .news_box .news_content .news_text i {
    display: block;
    padding: 0px 24px;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: right;
    margin: 16px 0px;
    color: #9da1aa;
  }
  .news .news_box .news_content .news_text p {
    height: 88px;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #737783;
    align-items: center;
    padding: 0px 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    display: -webkit-box;
  }
}
</style>
<style scoped>
@media screen and (max-width: 800px/*no*/) {
  .news .news_box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .news_content {
    width: 600px !important;
    margin-bottom: 20px !important;
    margin: 0 auto;
  }
  .news .news_box .news_content img {
    width: 100% !important;
  }
  .news .more {
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */
    text-align: right;
    /* B-Gray/B-Gray-600 */
    color: #2a90ee;
    margin-bottom: 10px;
    margin-right: 15px;
  }
  .news .news_box .news_content .news_text p {
    height: 79px;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #737783;
    align-items: center;
    padding: 0px 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    display: -webkit-box;
  }
  .news {
    padding: 72px 0px;
  }

  .news .more:hover {
    cursor: pointer;
  }

  .news .news_box .news_content .news_text {
    background-color: #ffffff;
    border: 1px solid #f0f0f5;
    box-sizing: border-box;
  }
  .news .news_box .news_content .news_text span {
    width: 100%;
    margin: 16px 0px;
    display: inline-block;
    padding: 0 24px;
    font-size: 16px;
    font-family: PingFangSC-Medium-Regular, PingFangSC-Medium;
    font-weight: 400;
    color: #161d30;
    line-height: 24px;
    height: 48px;
  }
  .news .news_box .news_content .news_text span:hover {
    cursor: pointer;
  }
  .news .news_box .news_content .news_text i {
    display: block;
    padding: 0px 24px;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: right;
    margin: 16px 0px;
    color: #9da1aa;
  }
}
</style>
