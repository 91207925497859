import store from "@/store";
import { Message } from "element-ui";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
const originReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
VueRouter.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
    originReplace.call(this, location, resolve, reject);
  } else {
    originReplace.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};

const constantRoutes = [
  {
    path: "/",
    redirect: "/home",
  },
  //主页
  {
    path: "/home",
    name: "home",
    redirect: "home/index",
    component: () => import("@/views/Home"),
    hidden: true,
    children: [
      //主页
      {
        path: "index",
        name: "index",
        component: () => import("@/views/Home/Main"),
        meta: {
          title: "首页",
        },
      },
      // 帮助页
      {
        path: "help",
        name: "help",
        component: () => import("@/views/Home/Help"),
        meta: {
          title: "使用帮助",
        },
        children: [
          {
            path: "content/:id?",
            name: "content",
            component: () => import("@/views/Home/Help/Content"),
            meta: {
              title: "使用帮助",
            },
          },
          {
            path: "search/:text",
            name: "search",
            component: () => import("@/views/Home/Help/Search"),
            meta: {
              title: "使用帮助",
            },
          },
        ],
      },
      // 产品服务
      {
        path: "product",
        name: "product",
        redirect: "product/application",
        component: () => import("@/views/Home/Product"),
        children: [
          {
            path: "application",
            name: "application",
            component: () =>
              import("@/views/Home/Product/components/Application"),
            meta: {
              title: "产品服务",
            },
          },
          {
            path: "application2",
            name: "application2",
            component: () =>
              import("@/views/Home/Product/components/Application2"),
            meta: {
              title: "产品服务",
            },
          },
          {
            path: "application3",
            name: "application3",
            component: () =>
              import("@/views/Home/Product/components/Application3"),
            meta: {
              title: "产品服务",
            },
          },
          {
            path: "application4",
            name: "application4",
            component: () =>
              import("@/views/Home/Product/components/Application4"),
            meta: {
              title: "产品服务",
            },
          },
        ],
      },
      // 解决方案
      {
        path: "solution",
        name: "solution",
        redirect: "solution/internet",
        component: () => import("@/views/Home/Solution"),
        children: [
          {
            path: "internet",
            name: "internet",
            component: () =>
              import("@/views/Home/Solution/components/Internet"),
            meta: {
              title: "解决方案",
            },
          },
          {
            path: "money",
            name: "money",
            component: () => import("@/views/Home/Solution/components/Money"),
            meta: {
              title: "解决方案",
            },
          },
          {
            path: "health",
            name: "health",
            component: () => import("@/views/Home/Solution/components/Health"),
            meta: {
              title: "解决方案",
            },
          },
        ],
      },
      // 服务体系
      {
        path: "service",
        name: "service",
        component: () => import("@/views/Home/Serve"),
        children: [
          {
            path: "standard",
            name: "standard",
            component: () => import("@/views/Home/Serve/components/Standard"),
            meta: {
              title: "服务体系",
            },
          },
          {
            path: "ee",
            name: "ee",
            component: () => import("@/views/Home/Serve/components/Ee"),
            meta: {
              title: "服务体系",
            },
          },
        ],
      },
      // app备案
      {
        path: "app",
        name: "app",
        component: () => import("@/views/Home/App"),
        meta: {
          title: "备案专区",
        },
      },
      // app备案
      {
        path: "aboutus",
        name: "aboutus",
        component: () => import("@/views/Home/Aboutus"),
        meta: {
          title: "关于我们",
        },
      },
      // 新闻详情页
      {
        path: "detail/:id",
        name: "detail",
        component: () => import("@/views/Home/Detail"),
        meta: {
          title: "新闻",
        },
      },
      //新闻更多页
      {
        path: "more/:id",
        name: "more",
        component: () => import("@/views/Home/MoreNews"),
        meta: {
          title: "新闻",
        },
      },
    ],
  },
  // 管理中心
  {
    path: "/center",
    name: "center",
    redirect: "center/safe",
    component: () => import("@/layout"),
    children: [
      // 应用加固
      {
        path: "safe",
        redirect: "safe/reinforce",
        component: () => import("@/views/Center/Safe"),
        meta: {
          title: "应用加固",
          icon: "jiagu",
        },
        children: [
          {
            path: "reinforce",
            name: "reinforce",
            component: () => import("@/views/Center/Safe/components/Reinforce"),
            meta: {
              title: "开始加固",
            },
          },
          {
            path: "reinforcelist",
            name: "reinforcelist",
            component: () =>
              import("@/views/Center/Safe/components/Reinforcelist"),
            meta: {
              title: "已加固应用列表",
            },
          },
          {
            path: "ioslist",
            name: "ioslist",
            component: () => import("@/views/Center/Safe/components/IOSlist"),
            meta: {
              title: "iOS编译器列表",
            },
          },
        ],
      },
      //安全检测
      {
        path: "detection",
        name: "detection",
        redirect: "safe/scan",
        component: () => import("@/views/Center/Detection"),
        meta: {
          title: "安全检测",
          icon: "jiance",
        },
        children: [
          {
            path: "scan",
            name: "scan",
            component: () => import("@/views/Center/Detection/components/Scan"),
            meta: {
              title: "开始检测",
            },
          },
          {
            path: "scanlist",
            name: "scanlist",
            component: () =>
              import("@/views/Center/Detection/components/ScanList"),
            meta: {
              title: "检测报告列表",
            },
          },
        ],
      },
      //我的套餐
      {
        path: "combo",
        name: "combo",

        component: () => import("@/views/Center/Combo"),
        meta: {
          title: "我的套餐",
          icon: "mybuy",
        },
      },
      //套餐商城
      {
        path: "store",
        name: "store",
        component: () => import("@/views/Center/Store"),
        meta: {
          title: "套餐商城",
          icon: "buy",
        },
      },
      //用户中心
      {
        path: "user",
        name: "user",
        component: () => import("@/views/Center/User"),
        meta: {
          title: "用户中心",
          icon: "user",
        },
        children: [
          {
            path: "number",
            name: "number",
            component: () => import("@/views/Center/User/components/Number"),
            meta: {
              title: "我的账号",
            },
          },
          {
            path: "logout",
            name: "logout",
            component: () =>
              import("@/views/Center/User/components/Number/components/logout"),
            meta: {
              title: "注销账号",
            },
            hidden: true,
          },
          {
            path: "autonym",
            name: "autonym",
            component: () => import("@/views/Center/User/components/Autonym"),
            meta: {
              title: "实名信息",
            },
          },
          {
            path: "oder",
            name: "oder",
            component: () => import("@/views/Center/User/components/Oder"),
            meta: {
              title: "我的订单",
            },
          },
          {
            path: "invoice",
            name: "invoice",
            component: () => import("@/views/Center/User/components/Invoice"),
            meta: {
              title: "发票管理",
            },
          },
          {
            path: "record",
            name: "record",
            component: () => import("@/views/Center/User/components/Record"),
            meta: {
              title: "使用记录",
            },
          },
          {
            path: "message",
            name: "message",
            component: () => import("@/views/Center/User/components/Message"),
            meta: {
              title: "消息记录",
            },
          },
          {
            path: "coupon",
            name: "coupon",
            component: () => import("@/views/Center/User/components/Coupon"),
            meta: {
              title: "我的优惠券",
            },
          },
        ],
      },
      {
        path: "authentication",
        name: "authentication",
        component: () => import("@/views/Center/Authentication"),
        meta: {
          title: "实名认证",
        },
        hidden: true,
      },
      {
        path: "invoiceinfo",
        name: "invoiceinfo",
        component: () => import("@/views/Center/InvoiceInfo"),
        meta: {
          title: "开票信息",
        },
        hidden: true,
      },
      {
        path: "reinforcedetails",
        name: "reinforcedetails",
        component: () =>
          import("@/views/Center/Safe/components/Reinforcedetails"),
        meta: {
          title: "加固详情",
        },
        hidden: true,
      },
      {
        path: "scandetails",
        name: "scandetails",
        component: () =>
          import("@/views/Center/Detection/components/ScanDetails"),
        meta: {
          title: "扫描详情",
        },
        hidden: true,
      },
      {
        path: "strategy/:id?/:userId?/:productAppId?/:appType?",
        name: "strategy",
        component: () => import("@/views/Center/Safe/components/Strategy"),
        meta: {
          title: "策略列表",
        },
        hidden: true,
      },
      {
        path: "configuration/:productAppId?/:rowId?",
        name: "configuration",
        component: () => import("@/views/Center/Safe/components/Configura"),
        meta: {
          title: "策略配置",
        },
        hidden: true,
      },
      {
        path: "success",
        name: "success",
        component: () => import("@/views/Center/Success"),
        meta: {
          title: "提交成功",
        },
        hidden: true,
      },
      {
        path: "paysuccess",
        name: "paysuccess",
        component: () => import("@/views/Center/PaySuccess"),
        meta: {
          title: "支付成功",
        },
        hidden: true,
      },
      // {
      //   path: "combodetails",
      //   name: "combodetails",
      //   component: () => import("@/views/Center/Combo/components/Combodetails"),
      //   meta: {
      //     title: "套餐详情",
      //   },
      //   hidden: true,
      // },
      {
        path: "pay",
        name: "pay",
        component: () => import("@/views/Center/Pay"),
        meta: {
          title: "订单支付",
        },
        hidden: true,
      },
    ],
  },
  {
    path: "/newpay",
    name: "newpay",
    component: () => import("@/views/Center/newPay"),
    meta: {
      title: "支付",
    },
    hidden: true,
  },
  {
    path: "/protocol",
    name: "protocol",
    component: () => import("@/views/Protocol"),
  },
  {
    path: "/wxlogin",
    name: "wxlogin",
    component: () => import("@/views/Wxlogin"),
    meta: {
      title: "微信登录",
    },
  },
  {
    path: "/404",
    component: () => import("@/views/404"),
    hidden: true,
  },
  {
    path: "*",
    redirect: "/404",
    hidden: true,
  },
];

const router = new VueRouter({
  // base: "/jiagu",
  mode: "history",
  routes: constantRoutes,
});
const whitepath = ["home", "wxlogin"];
router.beforeEach(async (to, from, next) => {
  const token = store.state.user.token;
  document.title = to.meta.title;
  if (store.getters.menu.length == 0) {
    await store.dispatch("user/getMenu");
    store.getters.menu.map((item) => {
      if (to.path == item.router) {
        to.meta.seoKey = item.seoKey;
        to.meta.seoTitle = item.seoTitle;
        to.meta.seoValue = item.seoValue;
      }
    });
  } else {
    store.getters.menu.map((item) => {
      if (to.path == item.router) {
        to.meta.seoKey = item.seoKey;
        to.meta.seoTitle = item.seoTitle;
        to.meta.seoValue = item.seoValue;
      }
    });
  }

  if (token) {
    store.dispatch("user/getAgain").then(
      (res) => {
        if (to.query.wxRepeat == 1) {
          Message.error("该微信已绑定其他账号");
          next("/center/user/number");
        } else {
          next();
        }
      },
      () => {
        console.log("失败");
        store.dispatch("user/loginOut");
      }
    );
  } else {
    if (whitepath.includes(to.matched[0].name)) {
      next();
    } else {
      // window.$bus.$emit("dialog", { flag: true, to: to.name });

      next("/");
    }
  }
});

export default router;
