import Vue from "vue";
import App from "./App.vue";
import "normalize.css/normalize.css";
import router from "./router/index";
import store from "./store";
import "./settingComponent";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import Title from "@/components/Title";
import Pagination from "@/components/Pagination";
import "/src/styles/index.scss"; // global css
import "@/icons"; // icon
import VDistpicker from "v-distpicker";
import MetaInfo from "vue-meta-info";
// import "./utils/flexible";
import "lib-flexible/flexible";
Vue.config.productionTip = false;
Vue.use(MetaInfo);
Vue.use(ElementUI);
Vue.component("Title", Title);
Vue.component("Pagination", Pagination);
Vue.component("VDistpicker", VDistpicker);
Vue.prototype.contentSize = function (s) {
  var count = 0;
  for (var i = 0; i < s.length; i++) {
    if (s.charCodeAt(i) > 255) {
      //charCodeAt() 方法可返回指定位置的字符的 Unicode 编码
      //charCodeAt(i)>255是用来判断非常规字符的。如中文、日文。这些字符一个占用2个字节。所以加2。如果是常规字符,一个占用一个字节
      count = count + 2;
    } else {
      count = count + 1;
    }
  }
  return count;
};

window.$bus = new Vue();
new Vue({
  render: (h) => h(App),
  beforeCreate() {
    Vue.prototype.$message = this.$message;
  },
  router,
  store,
}).$mount("#app");
